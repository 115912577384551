@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.s-free-asses {
    position: relative;

    color: #ffffff;

    &::before {
        content: '';

        width: 100%;

        position: absolute;
        top: 50px;
        bottom: 50px;
        z-index: -1;
        transform: skewY(7deg);

        background-color: $color-laguna;
    }
    &_white {
        position: static;

        color: #222222;

        &::before {
            display: none;
        }

        h2,
        .s-free-asses__sub-heading {
            color: #3e3e3e;
        }
        .s-free-asses__container {
            padding-bottom: 80px;
            padding-top: 80px;

            @media (min-width: $medium-size) {
                padding-top: 100px;
                padding-bottom: 100px;
            }
        }
        .s-free-asses__btn {
            @include btn__color-orange-dark;
        }
        
        .bl-step-list__arrow {
            color: $color-laguna-dark;
        }
    }

    &_hero {
        
        &::before {
            top: -200%;
            
            background-color: $color-laguna-dark;  
        }
        
        .s-free-asses__container {
            padding-top: 60px;
        }
        
        .s-free-asses__sub-heading {
            & ~ p {
                max-width: 525px;
            }
        }
        
        .s-free-asses__container {
            @media (min-width: 768px) {
                grid-template-columns: 1fr;
                grid-row-gap: 40px;
            }
            @media (min-width: 1024px) {
                grid-template-columns: 1fr 1.5fr;
                grid-column-gap: 32px;
            }
            @media (min-width: 1280px) {
                grid-template-columns: 1fr 2.15fr;
            }
            @media (min-width: 1440px) {
                grid-column-gap: 80px;
            }
        }
        
        .s-free-asses__head {
            @media (min-width: 768px) {
                grid-column: 1;
            }
            @media (min-width: 1024px) {
                grid-column: 1 / 2 span;
            }
        }
        
        .s-free-asses__video {
            @media (min-width: 1024px) {
                grid-column: 1 / 2 span;
                aspect-ratio: 17 / 5;
            }
            
            & ~ div {
                @media (min-width: 1024px) {
                    margin-top: 50px;
                }
            }
        }
        
        .s-free-asses__wr-list {
            @media (min-width: 768px) {
                padding-left: 0;
            }
        }
        
        .s-free-asses__wr-bg-img {
            @media (min-width: 1280px) {
                width: 530px;
            }
            
            &_bot {
                @media (min-width: 1280px) {
                    bottom: 300px;
                    transform: translateX(-100px)
                }
            }
        }
        .s-free-asses__video {
            .m-video__thumb {
                img {
                    @media (min-width: 1024px) {
                        object-position: center top;
                    }
                }
            }
        }
    }
}
.s-free-asses__container {
    display: grid;
    grid-row-gap: 30px;

    padding-top: 150px;
    padding-bottom: 150px;

    position: relative;
    overflow: hidden;

    @media (min-width: $small-size) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: $large-size) {
        padding-top: 250px;
        padding-bottom: 100px;
    }

    &_last {
        padding-bottom: 100px!important;

        @media (min-width: $medium-size) {
            padding-bottom: 200px!important;
        }
    }

    &_top-pad-min {
        padding-top: 0!important;

        @media (min-width: 768px) {
            padding-top: 80px!important;
        }
    }
}
.s-free-asses__wr-bg-img {
    width: 350px;
    height: 280px;

    @include img-wrap;

    position: absolute;
    top: 50px;
    right: 0;
    z-index: -1;
    transform: translateX(40px);

    opacity: .15;

    @media (min-width: $large-size) {
        width: 650px;
        height: 500px;
    }

    &_bot {
        width: 280px;
        height: 280px;

        top: auto;
        right: auto;
        bottom: 80px;
        left: 0;
        transform: translateX(-20px);

        opacity: 1;

        @media (min-width: $large-size) {
            width: 320px;
            height: 320px;
        }
    }

    img,
    svg {
        object-fit: contain;
    }
}

.s-free-asses__head {
    @media (min-width: $smallest-size) {
        max-width: 450px;
    }
    @media (min-width: $small-size) {
        grid-column: 1 / 2 span;

        max-width: none;
    }
}
.s-free-asses__heading {
    color: #ffffff;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
.s-free-asses__heading-italic {
    display: block;

    @media (min-width: $small-size) {
        display: inline-block;
    }
}
.s-free-asses__sub-heading {
    margin-bottom: 20px;

    color: #ffffff;
}
.s-free-asses__head-text {
    @media (min-width: $small-size) {
        max-width: 525px;
    }
}

.s-free-asses__video {
    align-self: start;

    width: 100%;
    
    @include video-wrap;
    @include aspect-ratio-w-pseudo(17, 10);

    @media (min-width: 768px) {
        grid-column: 1;
        grid-row: 3 span;
    }
}

.s-free-asses__wr-list {
    @media (min-width: $small-size) {
        grid-column: 2;
        grid-row: 2 / 3 span;
        
        padding-left: 24px;
    }
    @media (min-width: $medium-size) {
        padding-left: 80px;
    }
}
.bl-step-list__arrow {
    position: absolute;
    left: 34px;
    top: 55%;

    font-size: 22px;
}
.bl-step-list__item {
    padding-left: 100px;
    padding-top: 2px;

    position: relative;

    @media (min-width: $large-size) {
        padding-left: 115px;
    }

    &:not(:last-child) {
        padding-bottom: 20px;
    }
    &:last-child {
        .bl-step-list__arrow {
            display: none;
        }
    }
}
.bl-step-list__num {
    display: block;

    padding: 6px 18px;

    position: absolute;
    top: 0;
    left: 0;

    font: {
        family: $font-family-solid;
        size: 12px;
        weight: 500;
    };
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $color-laguna-dark;

    background-color: #F2FAFB;
    border-radius: 8px;
}
.bl-step-list__text {
    @media (min-width: $smallest-size) {
        max-width: 400px;
    }
}
.s-free-asses__btn {
    width: 100%;
    margin-top: 30px;

    text-align: center;

    @media (min-width: $small-mid-size) {
        width: auto;
    }
}

.s-free-asses__wr-form {
    padding: 12px;
    
    border-radius: 10px;
    background-color: #fff;
    
    @media (min-width: 480px) {
        padding: 20px;
        
        border-radius: 20px;
    }
    @media (min-width: 768px) {
        padding: 50px;
    }
    @media (min-width: 1280px) {
        padding: 50px 80px;
    }
    
    .gform_wrapper.gravity-theme .gform_fields {
        grid-column-gap: 16px;
        grid-row-gap: 32px;
        
        @media (min-width: 1280px) {
            grid-column-gap: 32px;
        }
    }
    
    .gform_wrapper.gravity-theme .gfield_label {
        margin-bottom: 0;
        
        font-family: Montserrat,sans-serif;
        font-size: 14px;
        line-height: 140%;
        font-weight: 500;
        color: #007178;
    }
    .gform_wrapper .gfield_required {
        font-size: inherit;
        line-height: inherit;
        color: #FF6B58;
    }
    
    input {
        &:not([type='submit']):not([type='radio']):not([type='checkbox']) {
            border: none;
            border-bottom: 1px solid #007178;
            padding: 8px 0 10px;
            
            font-size: 16px;
            line-height: 160%;
            
            &::placeholder {
                color: #ADADAD;
            }
            
            &:focus {
                outline: none;
                
                box-shadow: 0px 8px 8px -8px #007178;
            }
        }
        &[type='submit'] {
            border: 1px solid #007178;
            padding: 15px 25px;
            
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            line-height: 125%;
            font-weight: 600;
            color: #fff;
            
            background-color: #007178;
            border-radius: 50px;
            cursor: pointer;
            
            &:hover {
                @media (hover: hover) {
                    color: #007178;
                    
                    background-color: transparent;
                }
            }
        }
    }
    .gform_wrapper.gravity-theme select {
        border: none;
        border-bottom: 1px solid #007178;
        padding: 8px 0 10px;
            
        font-size: 16px;
        line-height: 160%;
            
        &:focus {
            outline: none;
               
            box-shadow: 0px 8px 8px -8px #007178;
        }
    }
    .ginput_container.ginput_container_select {
        position: relative;
        
        &::after {
            content: "";
            width: 8px;
            height: 8px;
            border-right: 1px solid #adadad;
            border-bottom: 1px solid #adadad;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-70%,-50%) rotate(45deg);
        }
    }
    .gchoice {
        label {
            color: #222222;
        }
    }
}
.bl-step-list__heading {
    @include heading;
    @include heading__fourth;

    margin-bottom: 10px;
}